.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 40px; /* パディングを増やす */
    border-radius: 8px;
    width: 90%; /* モーダルの幅を調整 */
    max-width: 1100px; /* 最大幅を指定 */
    height: 40%; /* 高さを自動調整 */
    max-height: 200vh; /* 最大高さを指定 */
    transform: translate(10%, -10%); /* 中央配置のために追加 */
  }